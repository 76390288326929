'use client';
import { Fragment, PropsWithChildren } from 'react';

import cn from 'classnames';
import { useTranslations } from 'next-intl';
import Link from 'next/link';

import {
  NEWS_PAGE_APP_ENDPOINT,
  PARTNERS_APP_ENDPOINT,
  CONTACT_APP_ENDPOINT,
  PROFILE_APP_ENDPOINT,
  GAMES_APP_ENDPOINT,
  ABOUT_COMPANY_APP_ENDPOINT,
  REGISTRATION_APP_ENDPOINT,
  LOGIN_APP_ENDPOINT,
  RESTORE_PASSWORD_APP_ENDPOINT,
  SLOT_MACHINES_COMPARE_APP_ENDPOINT,
  CAREER_APP_ENDPOINT,
} from '@/src/api/endpoints/app';
import { BreadCrumbType, BreadCrumbsConfigType } from '@/src/domain/common/BreadCrumbsConfigType';
import Container from '@/src/hocs/Container';
// import useUrlState from '@/src/hooks/useUrlState';
import { usePathname } from '@/src/i18n/routing';

import { Typography } from '../Typography';

import styles from './BreadCrumbs.module.scss';

interface Props {
  config?: BreadCrumbsConfigType;
}

const BreadCrumbs = ({ children, config = { crumbs: [] } }: PropsWithChildren<Props>) => {
  const t = useTranslations('breadcrumbs');
  // const { url } = useUrlState({});
  const path = usePathname();

  if (config?.isHided) {
    return <></>;
  }

  const breadCrumbsItems = [
    'main',
    ...path.split('/').filter(i => i !== 'profile'),
    ...config?.crumbs.map(({ name }) => name),
  ].filter(item => !!item);
  const nextPathLinks = config?.crumbs.reduce(
    (prev, { link, name, disabled }) => ({
      ...prev,
      [name]: { link: link ?? path, name, disabled },
    }),
    {},
  );

  const breadCrumbsLink: Record<string, BreadCrumbType> = {
    main: {
      name: t('main'),
      link: '/',
    },
    news: {
      name: t('news'),
      link: NEWS_PAGE_APP_ENDPOINT,
    },
    partners: {
      name: t('partners'),
      link: PARTNERS_APP_ENDPOINT,
    },
    contact: {
      name: t('contact'),
      link: CONTACT_APP_ENDPOINT,
    },
    games: {
      name: t('games'),
      link: GAMES_APP_ENDPOINT,
    },
    profile: {
      name: t('profile'),
      link: PROFILE_APP_ENDPOINT,
    },
    // ['slot-machines']: {
    //   name: t('slot_machines'),
    //   link: SLOT_MACHINES_APP_ENDPOINT,
    // },
    compare: {
      name: t('slot_machines_compare'),
      link: SLOT_MACHINES_COMPARE_APP_ENDPOINT,
    },
    about: {
      name: t('about'),
      link: ABOUT_COMPANY_APP_ENDPOINT,
    },
    career: {
      name: t('career'),
      link: CAREER_APP_ENDPOINT,
    },
    registration: {
      name: t('registration'),
      link: REGISTRATION_APP_ENDPOINT,
    },
    login: {
      name: t('login'),
      link: LOGIN_APP_ENDPOINT,
    },
    restore: {
      name: t('restore_password'),
      link: RESTORE_PASSWORD_APP_ENDPOINT,
    },
    ...nextPathLinks,
  };

  return (
    <>
      {breadCrumbsItems.length > 1 ? (
        <Container>
          <ul className={styles.list}>
            {breadCrumbsItems.map((crumb, idx) => {
              if (!crumb) return;
              const link = breadCrumbsLink[crumb]?.link ?? '/';
              const title = breadCrumbsLink[crumb]?.name;
              const isDisabled = breadCrumbsLink[crumb]?.disabled;
              const isCrumbExist = !!breadCrumbsLink[crumb];

              return isCrumbExist ? (
                <li
                  key={idx}
                  className={cn(styles.list__item, {
                    [styles['list__item--active']]: path === link,
                    [styles['list__item--disabled']]: isDisabled,
                  })}
                >
                  <Link href={link}>
                    <Typography.Paragraph level={2}>{title}</Typography.Paragraph>
                  </Link>
                </li>
              ) : (
                <Fragment key={idx} />
              );
            })}
          </ul>
        </Container>
      ) : (
        <></>
      )}
      {children}
    </>
  );
};

export default BreadCrumbs;
